<template>
  <div class="PersonalCenterManagement">
    <a-spin :spinning="spinning">
    <div><a-button type="primary" @click="addOpen">新增</a-button></div>
<!-----------------------------------  table 表格区域 ----------------------------------->
    <a-table :columns="tableColumns" :data-source="tableData" :row-key="(record) => record.id" :pagination="false" style="margin-top: 20px">
          <!--      图标-->
      <span slot-scope="index,item" slot="icon">
        <img :src="item.icon" style="width: 65px;height: 40px">
      </span>
          <!--       状态-->
      <span slot-scope="index,item" slot="status">
        <a-switch checked-children="已发布" un-checked-children="未发布" v-model="item.isOpen === 1 ? true : false" @click="tableStatusOpenDown(item)"/>
      </span>
          <!--    排序-->
      <template slot="sort" slot-scope="text,item,index">
        <span v-if="index === 0">
          <a @click="postTableDataUpDown(item.id,'down')">下移</a>
        </span>
        <span v-else-if="index === tableData.length -1">
          <a @click="postTableDataUpDown(item.id,'up')">上移</a>
        </span>
        <span v-else>
          <a @click="postTableDataUpDown(item.id,'up')">上移</a>
          <a style="margin-left: 15px" @click="postTableDataUpDown(item.id,'down')">下移</a>
        </span>
      </template>
          <!--      操作-->
      <template slot-scope="text,item,index" slot="operation">
        <a @click="editOpen(item)">修改</a>
        <a-popconfirm
            title="是否删除？"
            ok-text="是"
            cancel-text="否"
            @confirm="removeTable(item.id, 'delete')"
        >
          <a style="margin-left: 15px" >删除</a>
        </a-popconfirm>
      </template>
    </a-table>

<!-----------------------------------  新增/修改 弹窗区域 ----------------------------------->
    <!--    新增-->
    <a-drawer
        title="新增"
        :width="450"
        :visible="addVisible"
        @close="addClose"
    >
      <a-form-model :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="addFormRules" ref="addForm">
        <a-form-model-item label="功能图标" prop="icon">
          <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width:80%;margin-bottom: -8px">
            <div class="uploadImg">
              <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0" accept="image/*" type="file" @change="uploadPic('addIcon')" ref="addIcon"/>
            </div>
            <div class="upload_image">
              <img
                  alt=""
                  v-model="addForm.icon"
                  v-if="addForm.icon"
                  style="width: 95px;height:95px;"
                  :src="addForm.icon"
              />
            </div>
          </div>
          <span style="font-size: 14px">注：尺寸 150*150，大小不超过500kb</span>
        </a-form-model-item>
        <a-form-model-item label="功能名称" prop="title">
          <a-input v-model="addForm.title" placeholder="请输入" :maxLength="200"/>
        </a-form-model-item>
        <a-form-model-item label="功能链接" prop="link">
          <a-textarea v-model="addForm.link" placeholder="请输入"/>
        </a-form-model-item>
        <a-form-model-item label="状态">
          <a-switch v-model="addForm.isOpen" checked-children="开" un-checked-children="关"/>
        </a-form-model-item>
        <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
          }"
        >
          <a-button @click="addClose">
            取消
          </a-button>
          <a-button style="margin-left: 15px" type="primary" @click="addSubmit()">
            确定
          </a-button>
        </div>
      </a-form-model>
    </a-drawer>

    <!--    修改-->
    <a-drawer
        title="修改"
        :width="450"
        :visible="editVisible"
        @close="editClose"
    >
      <a-form-model :model="editForm" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="addFormRules" ref="editForm">
        <a-form-model-item label="功能图标" prop="icon">
          <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width:80%;margin-bottom: -8px">
            <div class="uploadImg">
              <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0" accept="image/*" type="file" @change="uploadPic('editIcon')" ref="editIcon"/>
            </div>
            <div class="upload_image">
              <img
                  alt=""
                  v-if="editForm.icon"
                  style="width: 95px;height:95px;"
                  :src="editForm.icon"
              />
            </div>
          </div>
          <span style="font-size: 14px">注：尺寸 150*150，大小不超过500kb</span>
        </a-form-model-item>
        <a-form-model-item label="功能名称" prop="title">
          <a-input v-model="editForm.title" placeholder="请输入" :maxLength="200"/>
        </a-form-model-item>
        <a-form-model-item label="功能链接" prop="link">
          <a-textarea v-model="editForm.link" placeholder="请输入"/>
        </a-form-model-item>
        <a-form-model-item label="状态">
          <a-switch v-model="editForm.isOpen === 1 ? true : false" checked-children="开" un-checked-children="关" />
        </a-form-model-item>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
          }"
        >
          <a-button @click="editClose">
            取消
          </a-button>
          <a-button style="margin-left: 15px" type="primary" @click="editSubmit">
            确定
          </a-button>
        </div>
      </a-form-model>
    </a-drawer>
    </a-spin>
  </div>
</template>
<script>
import {
  getPersonal, postPersonalAdd, postPersonalUpDown, putPersonalEditRemove
} from "@/service/systemApi"
import {update} from "@/utils/update";
export default {
  name: 'personalCenterManagement',
  data () {
    return {
      tableColumns: [
        {
          title: '图标',
          width: '10%',
          align: 'center',
          scopedSlots: {customRender: 'icon'}
        },
        {
          title: '功能名称',
          width: '15%',
          align: 'center',
          dataIndex: 'title'
        },
        {
          title: '链接',
          width: '25%',
          align: 'left',
          dataIndex: 'link'
        },
        {
          title: '发布时间',
          width: '20%',
          align: 'left',
          dataIndex: 'created_time'
        },
        {
          title: '状态',
          width: '10%',
          align: 'center',
          scopedSlots: {customRender: 'status'}
        },
        {
          title: '排序',
          width: '10%',
          scopedSlots: {customRender: 'sort'}
        },
        {
          title: '操作',
          width: '20%',
          scopedSlots: {customRender: 'operation'}
        }
      ],
      spinning: false,
      tableData: [],
      addVisible: false,
      editVisible: false,
      addForm: {
        isOpen: true,
        icon: ''
      },
      editForm: {
        icon: ''
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 17 },
      addFormRules: {
        title: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        link: [{ required: true, message: '请输入链接', trigger: 'blur' }],
        icon: [{ required: true, message: '请上传图片', trigger: 'blur' }],
      }
    }// dataReturn
  },
  created() {
    this.getTableData()
  },
  methods: {
    addClose () {
      this.addVisible = false
    },
    addOpen () {
      this.addVisible = true
    },
    async editOpen (row) { // 修改打开
      this.spinning = true
      let data = {
        "id" : row.id
      }
      const response = await getPersonal(data)
      if (response.code === 0) {
        this.editForm = response.data[0]
        this.editVisible = true
      }else {
        this.$message.warning("查询出错请联系管理员")
      }
      this.spinning = false
    },
    editClose () {
      this.editVisible = false
    },
    async getTableData () { // 获取 table 列表
      this.spinning = true
      const response = await getPersonal()
      if (response.code === 0) {
        this.tableData = response.data
      }else {
        this.$message.warning(response.message)
      }
        this.spinning = false
    },
    async postTableDataUpDown (id,type) { // table 列表点击上移下移
      this.spinning = true
      let data = {
        "moveUpDown" : type
      }
      const response = await postPersonalUpDown(id,data)
      if (response.code === 0) {
        await this.getTableData()
        this.$message.success("设置成功")
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    },
    async uploadPic(type){ // 上传图片
      let file = this.$refs[type].files[0]; // 获取 input 中的图片文件

      if (file !== undefined) {
        if (file.size < 512000){ // 限制图片大小
          const image = await update(file) // 调用方法将本地图片上传到服务器 返回值为服务器处理后的图片地址
          if(image.code === 0){
            if ( type === "addIcon" ) {
              this.addForm.icon = image.data.url
            }else {
              this.editForm.icon = image.data.url
            }
            this.$message.success("图片上传成功！")
          }else {
            this.$message.error(image.message)
          }
        }else {
          this.$message.error("请上传小于500kb的图片")
        }
      }
    },
    async addSubmit () { // 新增提交
      this.$refs.addForm.validate(async valid => { // 表单校验
        if (valid) {
          this.spinning = true
          let data = {
            icon: this.addForm.icon,
            isOpen: this.addForm.isOpen === true ? 1 : 0,
            link: this.addForm.link,
            title: this.addForm.title,
          }
          const response = await postPersonalAdd(data)
          if (response.code === 0){
            this.$message.success("添加成功！")
            await this.getTableData()
            this.addForm = {
              isOpen: true,
              icon: ''
            }
            this.addVisible = false
            this.$refs.addIcon.value = null // 新增后将图片上传的 input 值清空 防止下次上传相同 url 的图片无效
          }else {
            this.$message.error(response.message)
          }
          this.spinning = false
        }else {
          this.$message.error("请填写全部表单")
        }
      })
    },
    async removeTable (id, type) { // Table 删除
      this.spinning = true
      const response = await putPersonalEditRemove(id, type, {})
      if (response.code === 0){
        this.$message.success("删除成功！")
        await this.getTableData()
      }else {
        this.$message.error(response.message)
      }
      this.spinning = false
    },
    async editSubmit () { // 修改提交
      this.$refs.editForm.validate(async valid => {
        if (valid) {
          this.spinning = true
          let data = {
            id: this.editForm.id,
            icon: this.editForm.icon,
            isOpen: this.editForm.isOpen,
            link: this.editForm.link,
            title: this.editForm.title,
          }
          const response = await putPersonalEditRemove(data.id, 'set', data)
          if (response.code === 0) {
            this.$message.success("修改成功！")
            await this.getTableData()
            this.editVisible = false
            this.$refs.editIcon.value = null
          }else {
            this.$message.error(response.message)
          }
          this.spinning = false
        }else {
          this.$message.error("请填写正确表单")
        }
      })
    },
    async tableStatusOpenDown (row) { // table 列表中直接修改数据状态
      this.spinning = true
      let data = {
        "isOpen": row.isOpen === 1 ? 0 : 1
      }
      const response = await putPersonalEditRemove(row.id, 'set', data)
      if (response.code === 0) {
        this.$message.success("修改成功")
        await this.getTableData()
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    }
  }
}
</script>
<style lang="scss" scoped>
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background-image: url("../../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_image{
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
}
</style>